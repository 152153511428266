import styled from 'styled-components';

export const StyledOptionsModal = styled.div`
  width: 200px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 10;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 60px;
  right: 0;
`;

export const StyledOption = styled.div<{ isAlign?: boolean; isCursor?: boolean }>`
  display: flex;
  gap: 20px;
  align-items: ${({ isAlign }) => (isAlign ? 'center' : 'flex-start')};
  cursor: ${({ isCursor }) => (isCursor ? 'pointer' : 'default')};
`;
