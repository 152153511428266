import { ReactComponent as Dashboard } from 'src/assets/images/Dashboard.svg';
import { ReactComponent as Deposit } from 'src/assets/images/Deposit.svg';
import { ReactComponent as Withdraw } from 'src/assets/images/Withdraw.svg';
import { ReactComponent as Wallets } from 'src/assets/images/Wallets.svg';
import { ReactComponent as Users } from 'src/assets/images/Users.svg';
import { ReactComponent as Orders } from 'src/assets/images/Orders.svg';
import { ReactComponent as Transactions } from 'src/assets/images/Transactions.svg';
import { ReactComponent as BankAccount } from 'src/assets/images/BankAccount.svg';
import { ReactComponent as KYC } from 'src/assets/images/KYC.svg';
import { ReactComponent as WaitingList } from 'src/assets/images/WaitingList.svg';
import { ReactComponent as Metrics } from 'src/assets/images/Metrics.svg';
import { ReactComponent as Circle } from 'src/assets/images/Circle.svg';
import { ReactComponent as Graphics } from 'src/assets/images/Graphics.svg';
import { ReactComponent as SettingsSidebar } from 'src/assets/images/SettingsSidebar.svg';

export const SIDEBAR_BUTTONS = [
  { text: 'Dashboard', url: 'dashboard', img: Dashboard },
  { text: 'Deposit', url: 'deposit', img: Deposit },
  { text: 'Withdraw', url: 'withdraw', img: Withdraw },
  { text: 'Wallets', url: 'wallets', img: Wallets },
  { text: 'Users', url: 'users', img: Users },
  { text: 'Orders', url: 'orders', img: Orders },
  { text: 'Transactions', url: 'transactions', img: Transactions },
  { text: 'Bank Account', url: 'bank-account', img: BankAccount },
  { text: 'KYC', url: 'kyc', img: KYC },
  { text: 'Waiting List', url: 'waiting-list-users', img: WaitingList },
  { text: 'Settings', url: 'settings', img: SettingsSidebar },
];

export const RECENT_ACTIVITY_HEADERS = ['Activity ID', 'Amount', 'Date', 'Status'];

export const RECENT_ACTIVITY_DATA = [
  {
    activity: {
      id: 'DY04210S0XA5',
      hash: 'Hash: 2111853732263042',
    },
    date: 'May 23, 2022',
    amount: '$250.00',
    status: 'Completed',
  },
  {
    activity: {
      id: 'DY04210S0XA5',
      hash: 'Hash: 2111853732263042',
    },
    date: 'May 23, 2022',
    amount: '$250.00',
    status: 'Completed',
  },
  {
    activity: {
      id: 'DY04210S0XA5',
      hash: 'Hash: 2111853732263042',
    },
    date: 'May 23, 2022',
    amount: '$250.00',
    status: 'Completed',
  },
  {
    activity: {
      id: 'DY04210S0XA5',
      hash: 'Hash: 2111853732263042',
    },
    date: 'May 23, 2022',
    amount: '$250.00',
    status: 'Completed',
  },
  {
    activity: {
      id: 'DY04210S0XA5',
      hash: 'Hash: 2111853732263042',
    },
    date: 'May 23, 2022',
    amount: '$250.00',
    status: 'Completed',
  },
  {
    activity: {
      id: 'DY04210S0XA5',
      hash: 'Hash: 2111853732263042',
    },
    date: 'May 23, 2022',
    amount: '$250.00',
    status: 'Completed',
  },
];

export const PRICES = [
  { id: 1, name: 'BTC', subname: 'Bitcoin', price: '10$' },
  { id: 2, name: 'ETH', subname: 'Ethereum', price: '1230$' },
  { id: 3, name: 'USDT', subname: 'TetherUS', price: '44.1231$' },
  { id: 4, name: 'BNB', subname: 'BNB', price: '11230$' },
  { id: 5, name: 'XRP', subname: 'Ripple', price: '1230$' },
  { id: 6, name: 'ADA', subname: 'Cardano', price: '88420$' },
  { id: 7, name: 'BUSD', subname: 'BUSD', price: '110$' },
  { id: 8, name: 'MATIC', subname: 'Polygon', price: '11230$' },
  { id: 9, name: 'DOGE', subname: 'Degocoin', price: '510$' },
  { id: 10, name: 'SOL', subname: 'Solana', price: '1000$' },
];

export const WIDGETS = [
  { id: 1, title: 'User activity', subtitle: 'You have total 99 users.', type: 'users', img: Metrics },
  { id: 2, title: 'Orders', subtitle: 'You have total 389 orders.', type: 'orders', img: Circle },
  { id: 3, title: 'Transactions', subtitle: 'You have total 389 transactions.', type: 'transactions', img: Metrics },
  { id: 4, title: 'Bank accounts', subtitle: 'You have total 389 bank accounts.', type: 'bank-accounts', img: Circle },
  { id: 5, title: 'KYC', subtitle: 'You have total 389 KYC.', type: 'kyc', img: Graphics },
];

export const USERS_HEADERS = ['Full name', 'Email', 'ID', 'Verification', 'Created at', 'Details'];

export const KYC_HEADERS = ['Full name', 'User ID', 'KYC ID', 'Status', 'Country code', 'Address', 'Details'];

export const WAITING_LIST_HEADERS = ['Full name', 'Email', 'ID', 'Email status', 'Country code', 'Address', 'Details'];

export const BANK_ACCOUNTS_HEADERS = [
  'Account number',
  'ID',
  'Bank name',
  'Swift BIC Code',
  'User',
  'Status',
  'Created at',
  'Details',
];

export const TRANSACTIONS_HEADERS = ['ID', 'Currency', 'Kind', 'Recipient address', 'Status', 'Crypto ID'];

export const ORDERS_HEADERS = ['ID', 'Direction', 'Pair', 'Kind', 'Status', 'Created at', 'Details'];

export const WITHDRAWALS_HEADERS = ['ID', 'Type', 'Currency', 'Status', 'Actions'];

export const WITHDRAWALS_DATA = [
  {
    user_id: 'DY04210S0XA5',
    type: 'Manual',
    currency: 'HKD',
    status: 'PENDING',
  },
  {
    user_id: 'DY04210S0XA5',
    type: 'Manual',
    currency: 'HKD',
    status: 'PENDING',
  },
  {
    user_id: 'DY04210S0XA5',
    type: 'Manual',
    currency: 'HKD',
    status: 'PENDING',
  },
  {
    user_id: 'DY04210S0XA5',
    type: 'Manual',
    currency: 'HKD',
    status: 'PENDING',
  },
  {
    user_id: 'DY04210S0XA5',
    type: 'Manual',
    currency: 'HKD',
    status: 'PENDING',
  },
  {
    user_id: 'DY04210S0XA5',
    type: 'Manual',
    currency: 'HKD',
    status: 'PENDING',
  },
  {
    user_id: 'DY04210S0XA5',
    type: 'Manual',
    currency: 'HKD',
    status: 'PENDING',
  },
];

export const WALLETS_HEADERS = ['ID', 'Owner ID', 'Address', 'Currency', 'Balance', 'Details'];
