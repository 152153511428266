import { styled } from 'styled-components';

export const StyledHeader = styled.header`
  padding: 3.5vh 2vw 2.5vh 2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25);
  background: #fff;

  @media only screen and (max-width: 768px) {
    padding: 2.5vh 2vw 2.5vh;

    & > div:nth-child(1) {
      margin-left: auto;
    }
  }
`;

export const StyledIconsWrap = styled.div`
  display: flex;
  gap: 2vw;
`;
