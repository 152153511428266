import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const StyledLoaderSpinner = styled.div`
  width: 100px;
  height: 100px;
  border: 8px solid lightblue;
  border-top: 8px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;
