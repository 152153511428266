import React, { FC } from 'react';

import { StyledPaginationButtonsWrap } from './styled';

interface IPagination {
  currentPage: number;
  totalPages: number;
  // eslint-disable-next-line no-unused-vars
  setCurrentPage: (num: string | number) => void;
}

const Pagination: FC<IPagination> = ({ currentPage, totalPages, setCurrentPage }) => {
  const maxPageButtonsToShow = 5;

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageButtons = () => {
    const pageButtons: (string | number)[] = [];
    if (totalPages <= maxPageButtonsToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(i);
      }
    } else {
      const halfMaxButtons = Math.floor(maxPageButtonsToShow / 2);
      const startPage = Math.max(1, currentPage - halfMaxButtons);
      const endPage = Math.min(totalPages, currentPage + halfMaxButtons);

      if (startPage > 1) {
        pageButtons.push(1);
        if (startPage > 2) {
          pageButtons.push('...');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageButtons.push('...');
        }
        pageButtons.push(totalPages);
      }
    }

    return pageButtons.map((page, index) => (
      <span
        key={index}
        onClick={() => handlePageClick(page as string)}
        style={{
          fontWeight: 600,
          cursor: 'pointer',
          background: currentPage === page ? '#172A4F' : '',
          padding: '6px 12px',
          borderRadius: '8px',
          color: currentPage === page ? 'white' : '#172A4F',
        }}
      >
        {page}
      </span>
    ));
  };

  const handlePageClick = (page: string) => {
    if (page !== '...') {
      setCurrentPage(page);
    }
  };

  return (
    <StyledPaginationButtonsWrap>
      <button
        onClick={handlePrevClick}
        style={{
          border: 'none',
          cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
          fontSize: '24px',
          background: 'none',
        }}
        disabled={currentPage === 1}
      >
        {'<'}
      </button>
      {renderPageButtons()}
      <button
        onClick={handleNextClick}
        style={{
          border: 'none',
          cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
          fontSize: '24px',
          background: 'none',
        }}
        disabled={currentPage === totalPages}
      >
        {'>'}
      </button>
    </StyledPaginationButtonsWrap>
  );
};

export default Pagination;
