import styled, { css } from 'styled-components';

export const StyledSidebarButton = styled.button<{ isActive: boolean }>`
  background: transparent;
  border: none;
  padding: .75vh 0;
  display: flex;
  gap: 1.5vw;
  align-items: center;

  &:hover {
    background: #172A4F;
    border-radius: 40px;

    & > span:nth-child(1) > svg {
      fill: #E7EFFF;
    }

    & > span:nth-child(1) > svg > path {
      fill: #E7EFFF;
    }

    & > span:nth-child(2) {
      color: #fff;
    }
  }

  ${props => props.isActive && css`
    background: #172A4F;
    border-radius: 40px;

    & > span:nth-child(1) > svg {
      fill: #E7EFFF;
    }

    & > span:nth-child(1) > svg > path {
      fill: #E7EFFF;
    }

    & > span:nth-child(2) {
      color: #fff;
    }
  `}
`;

export const StyledIcon = styled.span`
  padding: 1vh 0 1vh 2.5vw;
  min-width: 20px;
  display: flex;
  width: 2.5vw;
`;