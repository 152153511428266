import React, { FC, ReactNode } from 'react';

import { StyledWrap } from './styled';

interface ILoaderWrap {
  loading: boolean;
  height?: string;
  children: ReactNode;
}

const LoaderWrap: FC<ILoaderWrap> = ({ loading, height, children }) => {
  return (
    <StyledWrap loading={loading} height={height || ''}>
      {children}
    </StyledWrap>
  );
};

export default LoaderWrap;
