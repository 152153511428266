import React, { useEffect, useState } from 'react';

import { useDispatch } from 'src/store/useDispatch';
import { useSelector } from 'react-redux';
import { fetchEntities } from 'src/store/slice/entities';

import PageHeader from 'src/components/PageHeader';
import Table from 'src/components/Table';
import Loader from 'src/components/Loader';
import LoaderWrap from 'src/components/LoaderWrap';

import { WAITING_LIST_HEADERS } from 'src/utils/constants';

const WaitingList = () => {
  const dispatch = useDispatch();
  const {
    entities: {
      waitingListUsers: { data, totalCount, totalPages, page, search, field },
    },
    loading,
  } = useSelector(({ entities }) => entities);

  const [currentPage, setCurrentPage] = useState(page);
  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  useEffect(() => {
    if (hasInitialFetch) {
      dispatch(
        fetchEntities({
          type: 'waiting-list-users',
          page: currentPage,
          fields: ['name', 'email', '_id', 'status', 'country_code', 'address'],
          field,
          search,
        })
      );
    }

    if (!data) {
      dispatch(
        fetchEntities({
          type: 'waiting-list-users',
          page: currentPage,
          fields: ['name', 'email', '_id', 'status', 'country_code', 'address'],
          field,
          search,
        })
      );
      setHasInitialFetch(true);
    }
  }, [currentPage]);

  return (
    <>
      <PageHeader
        title="Waiting list"
        subtitle={`You have a total of ${totalCount} early registered users.`}
        dropdownTitle="Name"
        dropDownItems={['Name', 'Email', 'Country code', 'Email status', 'Address']}
      />
      {loading ? (
        <LoaderWrap loading={loading} height="80vh">
          <Loader />
        </LoaderWrap>
      ) : (
        <Table
          headers={WAITING_LIST_HEADERS}
          data={data ?? []}
          columns=".8fr 1fr 1fr .7fr .6fr .9fr 0.5fr"
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={(page: number) => {
            setHasInitialFetch(true);
            setCurrentPage(page);
          }}
          hasAction
        />
      )}
    </>
  );
};

export default WaitingList;
