import { configureStore } from '@reduxjs/toolkit';
import currencies from './slice/currencies';
import notificationsCount from './slice/notificationsCount';
import withdrawCount from './slice/withdrawCount';
import depositCount from './slice/depositCount';
import user from './slice/user';
import appAlert from './slice/appAlert';
import pairs from './slice/currencyPairs';
import tickers from './slice/tickers';
import walletAddresses from './slice/walletAddresses';
import settings from './slice/settings';
import dashboardCount from './slice/dashboardCount';
import entities from './slice/entities';

const store = configureStore({
  reducer: {
    currencies,
    notificationsCount,
    withdrawCount,
    depositCount,
    user,
    appAlert,
    pairs,
    tickers,
    walletAddresses,
    settings,
    dashboardCount,
    entities,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
