import { styled } from 'styled-components';

export const StyledForm = styled.form<{ isFlex?: boolean }>`
  padding: 2% 4%;
  background: #fff;
  border-radius: 40px;
  margin-top: 3%;
  display: ${({ isFlex }) => isFlex ? 'flex' : 'block'};

  & > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 4%;
  }

  .form_header {
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    padding: 1% 0 3%;
    margin-left: -2%;
  }

  .label {
    display: inline-block;
    padding: 0 0 1vh 1vw;
    font-size: 14px;
    font-family: Inter-Medium;
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 7%;
  margin-bottom: 3vh;
  width: 60%;

  select,
  & > div {
    width: 100%;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background: #172a4f;
    color: #fff;
    border-radius: 40px;
    margin: 8% 0 0 1vw;
    padding: 2% 18%;
    font-family: Inter-Medium;
    align-self: flex-end;
    cursor: pointer;
  }
`;
