import { styled, css } from 'styled-components';

export const StyledTable = styled.div`
  background: #fff;
  padding: 4vh 2vw;
  border-radius: 40px;
  font-size: calc(10px + 0.4vw);
  height: 100%;
  overflow: scroll;
`;

export const StyledRow = styled.div<{ isHeader?: boolean; columns?: string }>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  padding: 2vh 0;
  min-width: 700px;

  ${(props) =>
    props.isHeader &&
    css`
      color: #8c918c;
      padding-bottom: 4vh;
    `}

  ${(props) =>
    !props.isHeader &&
    css`
      border-top: 1px solid #e7efff;
      & > span:nth-child(1) {
        font-weight: 600;
      }

      & > span:nth-child(2) {
        font-family: Inter-Medium;
      }
    `}

    .nested_cell {
      overflow-wrap: anywhere;
    }

    .nested_cell > span:nth-child(3) {
    display: inline-block;
    color: #aabdad;
    font-weight: 400;
    padding-top: 2%;
  }

  svg {
    cursor: pointer;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledBadge = styled.span<{ bg: string }>`
  display: flex;
  align-self: baseline;

  & > span {
    background: ${({ bg }) => bg};
    color: #fff;
    padding: 4px 18px;
    border-radius: 15px;
    text-transform: lowercase;
  }

  & > span:first-letter {
    text-transform: capitalize;
  }
`;

export const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  span {
    display: inline-block;
    padding: 6px;
    cursor: pointer;
    font-weight: bold;

    &.active {
      background-color: #172a4f;
      color: white;
    }
  }
`;

export const StyledCopyButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #007bff;
  position: absolute;
  left: -5px;
  top: -25px;
  padding: 0;
`;
