import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchEntities } from 'src/store/slice/entities';

import PageHeader from 'src/components/PageHeader';
import Table from 'src/components/Table';
import LoaderWrap from 'src/components/LoaderWrap';
import Loader from 'src/components/Loader';

import { ORDERS_HEADERS } from 'src/utils/constants';

const Orders = () => {
  const dispatch = useDispatch();
  const {
    entities: {
      orders: { data, totalCount, totalPages, page, search, field },
    },
    loading,
  } = useSelector(({ entities }) => entities);

  const [currentPage, setCurrentPage] = useState(page);
  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  useEffect(() => {
    if (hasInitialFetch) {
      dispatch(
        fetchEntities({
          type: 'orders',
          page: currentPage,
          fields: ['id', 'direction', 'pair', 'kind', 'status', 'created_at'],
          field,
          search,
        })
      );
    }

    if (!data) {
      dispatch(
        fetchEntities({
          type: 'orders',
          page: currentPage,
          fields: ['id', 'direction', 'pair', 'kind', 'status', 'created_at'],
          field,
          search,
        })
      );
      setHasInitialFetch(true);
    }
  }, [currentPage]);

  return (
    <>
      <PageHeader
        title="Orders"
        subtitle={`You have a total of ${totalCount} orders`}
        dropdownTitle="ID"
        dropDownItems={['ID', 'Direction', 'Kind', 'Status', 'Pair']}
      />
      {loading ? (
        <LoaderWrap loading={loading} height="80vh">
          <Loader />
        </LoaderWrap>
      ) : (
        <Table
          headers={ORDERS_HEADERS}
          data={data ?? []}
          columns="1.2fr .6fr .6fr .6fr .7fr 1fr .3fr"
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={(page: number) => {
            setHasInitialFetch(true);
            setCurrentPage(page);
          }}
          hasAction
        />
      )}
    </>
  );
};

export default Orders;
