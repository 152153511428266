import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchSettings } from 'src/store/slice/settings';

import PageHeader from 'src/components/PageHeader';
import Input from 'src/components/Input';
import Loader from 'src/components/Loader';
import LoaderWrap from 'src/components/LoaderWrap';
import Notification from 'src/components/Notification';

import request from 'src/request';
import { AUTH_URL } from 'src/configs';

import { StyledContainer, StyledForm } from 'src/components/Form/styled';

const Deposit = () => {
  const [loading, setLoading] = useState(false);
  const [{ message, isError }, setNotification] = useState({ message: '', isError: false });
  const [spread, setSpread] = useState(0);
  const [makerFee, setMakerFee] = useState(0);
  const [takerFee, setTakerFee] = useState(0);

  const dispatch = useDispatch();
  const { settings, loading: fetchSettingsLoading } = useSelector(({ settings }) => settings);

  const updateSettings = (value: number, name: string) => {
    setLoading(true);
    request
      .put(`${AUTH_URL}/v1/admin/settings`, { value, name })
      .then((data) => setNotification({ message: data.data.msg, isError: false }))
      .catch((e) => setNotification({ message: e.response.data.error || 'Error occured', isError: true }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (settings.length) {
      setSpread(settings.find((s: { name: string }) => s.name === 'SPREAD').value);
      setMakerFee(settings.find((s: { name: string }) => s.name === 'MAKER_FEE').value);
      setTakerFee(settings.find((s: { name: string }) => s.name === 'TAKER_FEE').value);
    }
  }, [settings]);

  useEffect(() => {
    if (!settings.length) {
      dispatch(fetchSettings());
    }
  }, []);

  return loading || fetchSettingsLoading ? (
    <LoaderWrap loading={loading || fetchSettingsLoading} height="80vh">
      <Loader />
    </LoaderWrap>
  ) : (
    <>
      <PageHeader title="Settings" hideSearch />
      {!!settings.length && (
        <StyledForm isFlex>
          <div>
            <Input
              type="input"
              label="Spread"
              placeholder="Spread"
              value={spread.toString()}
              setValue={(value) => !isNaN(value) && value > -0.0001 && value < 0.51 && setSpread(value)}
            />
            <StyledContainer>
              <button type="button" onClick={() => updateSettings(spread, 'SPREAD')}>
                Submit
              </button>
            </StyledContainer>
          </div>
          <div>
            <Input
              type="input"
              label="Maker fee"
              placeholder="Maker fee"
              value={makerFee.toString()}
              setValue={(value) => !isNaN(value) && value > -0.0001 && value < 0.51 && setMakerFee(value)}
            />
            <StyledContainer>
              <button type="button" onClick={() => updateSettings(makerFee, 'MAKER_FEE')}>
                Submit
              </button>
            </StyledContainer>
          </div>
          <div>
            <Input
              type="input"
              label="Taker fee"
              placeholder="Taker fee"
              value={takerFee.toString()}
              setValue={(value) => !isNaN(value) && value > -0.0001 && value < 0.51 && setTakerFee(value)}
            />
            <StyledContainer>
              <button type="button" onClick={() => updateSettings(takerFee, 'TAKER_FEE')}>
                Submit
              </button>
            </StyledContainer>
          </div>
        </StyledForm>
      )}
      {message && (
        <Notification
          message={message}
          isSuccess={!isError}
          handleClose={() => setNotification({ message: '', isError: false })}
        />
      )}
    </>
  );
};

export default Deposit;
