import React, { FC } from 'react';
import Pagination from '../Pagination';

import Row from './Row';

import { StyledTable, StyledRow as StyledHeader, StyledPagination } from './styled';

interface ITable {
  headers: string[];
  data: object[];
  columns: string;
  totalPages?: number;
  currentPage?: number;
  // eslint-disable-next-line no-unused-vars
  onPageChange?: (page: number) => void;
  hasAction?: boolean;
  hasActions?: boolean;
  loading?: boolean;
  showForm?: () => void;
}

const Table: FC<ITable> = ({
  headers,
  data,
  hasAction,
  hasActions,
  columns,
  totalPages,
  currentPage,
  onPageChange,
  showForm,
}) => {
  return (
    <>
      <StyledTable>
        <StyledHeader columns={columns} isHeader>
          {headers.map((title) => (
            <span key={title}>{title}</span>
          ))}
        </StyledHeader>
        {data.length ? (
          data.map((item, i) => (
            <Row
              key={headers[i]}
              item={item}
              hasAction={hasAction}
              hasActions={hasActions}
              columns={columns}
              showForm={showForm}
            />
          ))
        ) : (
          <div style={{ textAlign: 'center', padding: '100px' }}>There is no result.</div>
        )}
      </StyledTable>
      <StyledPagination>
        {totalPages ? (
          <Pagination
            currentPage={currentPage as number}
            totalPages={totalPages as number}
            // @ts-expect-error: TODO: Fix type
            setCurrentPage={onPageChange}
          />
        ) : null}
      </StyledPagination>
    </>
  );
};

export default Table;
