import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

export const StyledModalOverlay = styled.div<{ isOpen: boolean; loading?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  z-index: 101;
  animation: ${({ isOpen }) => (isOpen ? 'fadeIn 0.3s' : 'fadeOut 0.3s')};
`;

export const StyledModalContainer = styled.div<{ isOpen: boolean; loading?: boolean }>`
  position: relative;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? `calc(100vw - 1040px)` : '100vw')};
  width: 1000px;
  height: 100%;
  background-color: white;
  display: ${({ loading }) => (loading ? 'flex' : 'block')};
  align-items: ${({ loading }) => (loading ? 'center' : 'auto')};
  justify-content: ${({ loading }) => (loading ? 'center' : 'auto')};
  z-index: 2;
  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.3s ease-in-out;
  overflow: auto;
  padding: 20px 20px 40px;
  transition: 1s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

export const StyledCloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
`;

export const StyledButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 20px;
  margin-bottom: 30px;
`;

export const StyledButtonCancel = styled.button`
  background: #172a4f;
  border-radius: 15px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  min-width: 100px;
`;

export const StyledButtonOk = styled.button<{ isDisabled: boolean }>`
  background: ${({ isDisabled }) => isDisabled ? '#ccc' : '#172a4f'};
  border-radius: 15px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  min-width: 100px;
`;

export const StyledTextarea = styled.textarea`
  width: calc(100% - 50px);
  padding: 20px;
  min-height: 100px;
  resize: none;
  outline: none;
`;

export const StyledImg = styled.img`
  max-width: 300px;
  max-height: 300px;
`;

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const StyledSelect = styled.select`
  padding: 8px;
  margin-bottom: 20px;
  outline: none;
`;
