import React, { FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE_LOGIN } from 'src/utils/routes';

import { ReactComponent as User } from 'src/assets/images/User.svg';
import { ReactComponent as Settings } from 'src/assets/images/Settings.svg';
import { ReactComponent as Language } from 'src/assets/images/Language.svg';
import { ReactComponent as Logout } from 'src/assets/images/Logout.svg';

import { StyledOption, StyledOptionsModal } from './styled';

const OptionsModal: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (target instanceof Node && !modalRef.current?.contains(target)) {
      handleClose();
    }
  };

  const handleLogOut = () => {
    localStorage.removeItem('access');
    navigate(ROUTE_LOGIN);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <StyledOptionsModal ref={modalRef}>
      <StyledOption>
        <User />
        <span>My Account</span>
      </StyledOption>
      <StyledOption isAlign>
        <Language />
        <span>Language and region</span>
      </StyledOption>
      <StyledOption>
        <Settings />
        <span>Settings</span>
      </StyledOption>
      <StyledOption isCursor>
        <Logout />
        <span onClick={handleLogOut}>Log out</span>
      </StyledOption>
    </StyledOptionsModal>
  );
};

export default OptionsModal;
