import React, { FC } from 'react';

import { StyledInput, StyledSelect, StyledTextarea } from './styled';

interface IInput {
  type: 'input' | 'select' | 'textarea';
  label: string;
  value: string;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  setValue?: (e: any) => void;
  placeholder?: string;
}

const Input: FC<IInput> = ({ type, label, placeholder, value, setValue }) => {
  return (
    <>
      <span className="label">{label}</span>
      <br />
      {type === 'input' && (
        <StyledInput
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue && setValue(e.currentTarget.value)}
        />
      )}
      {type === 'select' && (
        <StyledSelect>
          <option value="" disabled selected>
            Select...
          </option>
          <option value="USD">USD</option>
          <option value="EURO">EURO</option>
          <option value="RUB">RUB</option>
        </StyledSelect>
      )}
      {type === 'textarea' && <StyledTextarea placeholder={placeholder} />}
    </>
  );
};

export default Input;
