import { styled } from 'styled-components';

import ArrowDown from 'src/assets/images/ArrowDown.svg';

export const StyledInput = styled.input`
  padding: 1.25vh 1.25vw;
  border: 2px solid #172a4f;
  border-radius: 40px;
  margin-bottom: 3vh;
  outline: none;

  &::placeholder {
    color: gray;
    font-family: Inter-Medium;
    font-size: 13px;
  }
`;

export const StyledSelect = styled.select`
  padding: 1.25vh 1.25vw;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid #172a4f;
  border-radius: 40px;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${ArrowDown});
  background-repeat: no-repeat;
  background-position: 95% center;
  cursor: pointer;
  color: gray;
  font-family: Inter-Medium;
  font-size: 13px;
  outline: none;

  option:not(:first-of-type) {
    color: black;
  }
`;

export const StyledTextarea = styled.textarea`
  padding: 2vh 2vw;
  border: 2px solid #172a4f;
  border-radius: 40px;
  width: calc(100% - 4vw);
  height: 15vh;
  resize: none;
`;
