import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchEntities } from 'src/store/slice/entities';

import Loader from 'src/components/Loader';
import LoaderWrap from 'src/components/LoaderWrap';
import PageHeader from 'src/components/PageHeader';
import Table from 'src/components/Table';

import { BANK_ACCOUNTS_HEADERS } from 'src/utils/constants';

const BankAccounts = () => {
  const dispatch = useDispatch();
  const {
    entities: {
      bank_accounts: { data, totalCount, totalPages, page },
    },
    loading,
  } = useSelector(({ entities }) => entities);

  const [currentPage, setCurrentPage] = useState(page);
  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  useEffect(() => {
    if (hasInitialFetch) {
      dispatch(
        fetchEntities({
          type: 'bank-accounts',
          page: currentPage,
          fields: ['account_number', 'id', 'bank_name', 'swift_bic_code', 'owner', 'status', 'created_at'],
        })
      );
    }

    if (!data) {
      dispatch(
        fetchEntities({
          type: 'bank-accounts',
          page: currentPage,
          fields: ['account_number', 'id', 'bank_name', 'swift_bic_code', 'owner', 'status', 'created_at'],
        })
      );
      setHasInitialFetch(true);
    }
  }, [currentPage]);

  return loading ? (
    <LoaderWrap loading={loading} height="80vh">
      <Loader />
    </LoaderWrap>
  ) : (
    <>
      <PageHeader
        title="Bank Accounts"
        subtitle={`You have a total of ${totalCount} accounts`}
        dropdownTitle={BANK_ACCOUNTS_HEADERS[0]}
        dropDownItems={BANK_ACCOUNTS_HEADERS}
      />
      <Table
        headers={BANK_ACCOUNTS_HEADERS}
        data={data ?? []}
        columns=".55fr .4fr .5fr .4fr .9fr .5fr .5fr .3fr"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={(page: number) => {
          setHasInitialFetch(true);
          setCurrentPage(page);
        }}
        hasAction
      />
    </>
  );
};

export default BankAccounts;
