import React, { FC, ReactNode } from 'react';

import Search from '../Search';
import Text from '../Text';

import { StyledHeader } from './styled';

const PageHeader: FC<{
  title: string;
  subtitle?: string;
  dropdownTitle?: string;
  dropDownItems?: string[];
  hideSearch?: boolean;
  children?: ReactNode;
}> = ({ title, subtitle, dropdownTitle, dropDownItems, hideSearch, children }) => {
  return (
    <>
      <StyledHeader>
        <Text size={28} color="#172A4F" family="Inter-Medium" weight={600} padding="0 0 1vh">
          {title}
        </Text>
        {!hideSearch && <Search title={dropdownTitle ?? title} dropDownItems={dropDownItems} />}
      </StyledHeader>
      {subtitle ? (
        <Text size={16} color="#172A4F" family="Inter-Medium" padding="0 0 6vh 1vw">
          {subtitle}
        </Text>
      ) : (
        children
      )}
    </>
  );
};

export default PageHeader;
