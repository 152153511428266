import React, { useEffect, useState } from 'react';

import { fetchEntities } from 'src/store/slice/entities';
import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';

import PageHeader from 'src/components/PageHeader';
import Table from 'src/components/Table';
import LoaderWrap from 'src/components/LoaderWrap';
import Loader from 'src/components/Loader';

import { WALLETS_HEADERS } from 'src/utils/constants';

import { StyledButton, StyledButtons } from './styled';

const Wallets = () => {
  const dispatch = useDispatch();
  const {
    entities: {
      wallets: { data, totalCount, totalPages, page, field, search },
    },
    loading,
  } = useSelector(({ entities }) => entities);

  const [currentPage, setCurrentPage] = useState(page);
  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  useEffect(() => {
    if (hasInitialFetch) {
      dispatch(
        fetchEntities({
          type: 'wallets',
          page: currentPage,
          fields: ['id', 'owner', 'address', 'currency', 'balance'],
          field,
          search,
        })
      );
    }

    if (!data) {
      dispatch(
        fetchEntities({
          type: 'wallets',
          page: currentPage,
          fields: ['id', 'owner', 'address', 'currency', 'balance'],
          field,
          search,
        })
      );
      setHasInitialFetch(true);
    }
  }, [currentPage]);

  return (
    <>
      <PageHeader
        title="Wallets / Assets"
        subtitle={`You have a total of ${totalCount} wallets`}
        dropdownTitle="Address"
        dropDownItems={['Address', 'Owner', 'Currency code']}
      >
        <StyledButtons>
          <StyledButton>Send</StyledButton>
          <StyledButton>Withdraw</StyledButton>
        </StyledButtons>
      </PageHeader>
      {loading ? (
        <LoaderWrap loading={loading} height="80vh">
          <Loader />
        </LoaderWrap>
      ) : (
        <Table
          headers={WALLETS_HEADERS}
          data={data ?? []}
          columns="0.7fr 0.7fr 0.3fr 0.3fr 0.2fr 0.2fr"
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={(page: number) => {
            setHasInitialFetch(true);
            setCurrentPage(page);
          }}
          hasAction
        />
      )}
    </>
  );
};

export default Wallets;
