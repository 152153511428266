import React, { useEffect, useState } from 'react';

import { useDispatch } from 'src/store/useDispatch';
import { useSelector } from 'react-redux';
import { fetchEntities } from 'src/store/slice/entities';

import PageHeader from 'src/components/PageHeader';
import Table from 'src/components/Table';
import Loader from 'src/components/Loader';
import LoaderWrap from 'src/components/LoaderWrap';

import { USERS_HEADERS } from 'src/utils/constants';

const Users = () => {
  const dispatch = useDispatch();
  const {
    entities: {
      users: { data, totalCount, totalPages, page, field, search },
    },
    loading,
  } = useSelector(({ entities }) => entities);

  const [currentPage, setCurrentPage] = useState(page);
  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  useEffect(() => {
    if (hasInitialFetch) {
      dispatch(
        fetchEntities({
          type: 'users',
          page: currentPage,
          fields: ['name', 'email', 'id', 'kyc_status', 'created_at'],
          field,
          search,
        })
      );
    }

    if (!data) {
      dispatch(
        fetchEntities({
          type: 'users',
          page: currentPage,
          fields: ['name', 'email', 'id', 'kyc_status', 'created_at'],
          field,
          search,
        })
      );
      setHasInitialFetch(true);
    }
  }, [currentPage]);

  return (
    <>
      <PageHeader
        title="Users"
        subtitle={`You have a total of ${totalCount} users`}
        dropdownTitle="Name"
        dropDownItems={['Name', 'Status', 'KYC status', 'Email']}
      />
      {loading ? (
        <LoaderWrap loading={loading} height="80vh">
          <Loader />
        </LoaderWrap>
      ) : (
        <Table
          headers={USERS_HEADERS}
          data={data ?? []}
          columns=".8fr 1.1fr 1fr .9fr .6fr .3fr"
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={(page: number) => {
            setHasInitialFetch(true);
            setCurrentPage(page);
          }}
          hasAction
          loading={loading}
        />
      )}
    </>
  );
};

export default Users;
