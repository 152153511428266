import React, { FC } from 'react';

import Input from '../Input';

import { StyledContainer, StyledForm, StyledWrap } from './styled';

type FormType = 'manual' | 'deposit';

const Form: FC<{ type: FormType }> = ({ type }) => {
  const isDeposit = type === 'deposit';

  return (
    <StyledForm>
      <span className="form_header">
        {isDeposit ? 'Manual Deposit' : 'Enter details and submit form for withdrawing'}
      </span>
      <br />
      <Input type="input" label={isDeposit ? 'Deposit to' : 'Withdraw from'} placeholder="Enter user ID" value="" />
      <br />
      <Input
        type="input"
        label={`Amount to be ${isDeposit ? 'deposited' : 'withdrawn'}`}
        placeholder="Enter..."
        value=""
      />
      <br />
      <StyledWrap>
        <div>
          <Input type="select" label="User currency" placeholder="Select" value="" />
        </div>
        <div>
          <Input
            type="select"
            label={`${isDeposit ? 'Deposit' : 'Withdraw in'} currency`}
            placeholder="Select"
            value=""
          />
        </div>
      </StyledWrap>
      <StyledContainer>
        <div>
          <Input type="textarea" label="Notes" value="" />
        </div>
        <button type="button">Submit</button>
      </StyledContainer>
    </StyledForm>
  );
};

export default Form;
