import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchEntities } from 'src/store/slice/entities';

import Loader from 'src/components/Loader';
import LoaderWrap from 'src/components/LoaderWrap';

import PageHeader from 'src/components/PageHeader';
import Table from 'src/components/Table';

import { TRANSACTIONS_HEADERS } from 'src/utils/constants';

const Transactions = () => {
  const dispatch = useDispatch();
  const {
    entities: {
      transactions: { data, totalCount, totalPages, page, field, search },
    },
    loading,
  } = useSelector(({ entities }) => entities);

  const [currentPage, setCurrentPage] = useState(page);
  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  useEffect(() => {
    if (hasInitialFetch) {
      dispatch(
        fetchEntities({
          type: 'transactions',
          page: currentPage,
          fields: ['id', 'currency', 'kind', 'recipient_address', 'status', 'crypto_api_transaction_request_id'],
          field,
          search,
        })
      );
    }

    if (!data) {
      dispatch(
        fetchEntities({
          type: 'transactions',
          page: currentPage,
          fields: ['id', 'currency', 'kind', 'recipient_address', 'status', 'crypto_api_transaction_request_id'],
          field,
          search,
        })
      );
      setHasInitialFetch(true);
    }
  }, [currentPage]);

  return (
    <>
      <PageHeader
        title="Transactions"
        subtitle={`You have a total of ${totalCount} transactions`}
        dropdownTitle="ID"
        dropDownItems={['ID', 'Kind', 'Status', 'Recipient address', 'Crypto ID', 'Currency code']}
      />
      {loading ? (
        <LoaderWrap loading={loading} height="80vh">
          <Loader />
        </LoaderWrap>
      ) : (
        <Table
          headers={TRANSACTIONS_HEADERS}
          data={data ?? []}
          columns="0.8fr .8fr 0.8fr 0.8fr 0.8fr 0.8fr"
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={(page: number) => {
            setHasInitialFetch(true);
            setCurrentPage(page);
          }}
        />
      )}
    </>
  );
};

export default Transactions;
