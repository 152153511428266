import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchEntities, updateEntityField, updateEntitySearch } from 'src/store/slice/entities';

import { getEntityFields, getEntityType, getRoute, hasOnlySpaces } from '../Table/helpers';

import { StyledSearchInput, StyledSearchSelect, StyledSearchWrap } from './styled';

const Search: FC<{ title?: string; dropDownItems?: string[] }> = ({ title, dropDownItems }) => {
  const [isSearchStarted, setIsSearchStarted] = useState(false);

  const { pathname } = useLocation();
  const ENTITY_TYPE = getEntityType(pathname);

  const ENTITY_TYPES = ['waitingListUsers', 'orders', 'transactions', 'users', 'kyc', 'wallets'];

  const { entities } = useSelector(({ entities }) => entities);

  const dispatch = useDispatch();

  const search = entities[ENTITY_TYPE as string]?.search || '';
  const field = entities[ENTITY_TYPE as string]?.field || title || '';
  const isValidSearch = !hasOnlySpaces(search);

  useEffect(() => {
    if (ENTITY_TYPES.includes(ENTITY_TYPE as string) && isSearchStarted && isValidSearch) {
      const timeoutId = setTimeout(() => {
        dispatch(
          fetchEntities({
            type: getRoute(pathname),
            page: 1,
            fields: getEntityFields(pathname),
            field,
            search,
          })
        );
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [search]);

  return (
    <StyledSearchWrap>
      <StyledSearchInput
        placeholder="Search..."
        value={search}
        onChange={(e) => {
          setIsSearchStarted(true);
          dispatch(
            updateEntitySearch({
              entityType: ENTITY_TYPE,
              search: e.currentTarget.value,
            })
          );
        }}
      />
      <StyledSearchSelect
        value={field}
        onChange={(e) =>
          dispatch(
            updateEntityField({
              entityType: ENTITY_TYPE,
              field: e.currentTarget.value,
            })
          )
        }
      >
        {dropDownItems?.map((i) => (
          <option key={i}>{i}</option>
        ))}
      </StyledSearchSelect>
    </StyledSearchWrap>
  );
};

export default Search;
