import styled from 'styled-components';
import Search from 'src/assets/images/Search.svg';

export const StyledSearchInput = styled.input`
  width: 250px;
  border: 2px solid #172a4f;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  padding-left: 40px;
  background-image: url(${Search});
  background-repeat: no-repeat;
  background-position: 7% center;
  background-size: 15px;

  @media only screen and (max-width: 1024px) {    
    &::placeholder {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 750px) {    
    width: 50%;
  }
`;

export const StyledSearchSelect = styled.select<{ color?: string }>`
  position: relative;
  background: #172a4f;
  border: none;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: calc(.5vw + 1.5px) 2em calc(.5vw + 1.5px) calc(.5vw + 1.5px);
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 25%;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="%23fff"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: 90% center;
  padding-right: 1em;
  width: 200px;
`;

export const StyledSearchWrap = styled.div`
  & > input,
  button {
    font-family: Inter-Medium;
  }

  display: flex;
  align-self: baseline;
  height: 35px;

  @media only screen and (max-width: 770px) {
    margin-bottom: 20px;
  }
`;
