import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_DASHBOARD } from 'src/utils/routes';

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ROUTE_DASHBOARD);
  }, []);

  return (
    <>
      <div>Home page...</div>
    </>
  );
};

export default HomePage;
